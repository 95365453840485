import * as React from 'react'
import styled from '../../../styles/styled'
import { ProjectInfoQuery } from '../../../graphql-types'
import useTranslations from '../../translations/useTranslations'

type Images = ProjectInfoQuery['visuals']['edges']

interface ImagesProps {
  images: Images
}

const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageCaption = styled.p`
  color: ${(props) => props.theme.colors.white};
  padding: 10px 0;
`

// currently using img instead of Gastby Img component due to IE/Safari problems, because Img doesn't work properly with
// React-Simple-Lightbox -> won't open
const StyledImage = styled.img`
  height: auto;
`

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Images: React.FC<ImagesProps> = ({ images }: ImagesProps) => {
  return (
    <ImagesContainer>
      {images.map(({ node }) => (
        <ImageItem key={node.id}>
          <StyledImage src={node!.fluid!.src} alt={useTranslations(node?.fields?.i18nCaptions)} />
          <ImageCaption>
            <span dangerouslySetInnerHTML={{ __html: useTranslations(node?.fields?.i18nCaptions) ?? '' }} />
          </ImageCaption>
        </ImageItem>
      ))}
    </ImagesContainer>
  )
}

export default Images
