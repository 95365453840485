import React from 'react'
import { LocaleContext } from '../layouts'
import { Maybe } from '../../graphql-types'

const useTranslations = (translations?: { de?: Maybe<string>; en?: Maybe<string> } | null): string | undefined => {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  if (translations && translations[locale]) {
    return translations[locale] || ''
  } else {
    throw new Error('no translation found')
  }
}

export default useTranslations
