import * as React from 'react'
import Index from '../components/Tab'
import { graphql } from 'gatsby'
import { ProjectInfoQuery } from '../graphql-types'
import FactItem, { FactItemType } from '../components/projects/FactItem'
import styled from '../styles/styled'
import { KeyFactItems } from '../components/projects/Tile'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Accordion from '../components/projects/accordion'
import { tabOrder } from './project-detail'
import { useTranslation } from 'react-i18next'
import LoadableSimpleReactLightbox from '../components/images/LoadableSimpleReactLightbox'

interface ProjectProps {
  data: ProjectInfoQuery
  pageContext: {
    locale: 'en' | 'de'
    project: string
  }
}

const ContentContainer = styled.div`
  display: flex;
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 66.6667%;
  padding-right: ${props => props.theme.dimensions.containerPadding}rem;
`

const FactsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 33.3333%;
  padding-left: ${props => props.theme.dimensions.containerPadding}rem;
`

const IntroductionContainer = styled.div`
  display: flex;
  color: ${props => props.theme.colors.white};
  margin-bottom: ${props => props.theme.dimensions.containerPadding}rem;
`

const keyFactItems: KeyFactItems[] = ['region', 'climate', 'keywords', 'coordinates']

const ProjectInfo: React.FC<ProjectProps> = (props: ProjectProps) => {
  const {
    data: { mdx, accordionContent, visuals }
  } = props

  const { keyFacts } = props.data!.mdx!.frontmatter!
  const { t } = useTranslation()

  return (
    <Index tabOrder={tabOrder} tabData={props.data} activeTab={mdx?.id ?? ''} subject={mdx?.fields?.subject ?? ''}>
      <FactItem
        type={FactItemType.header}
        title={mdx?.frontmatter?.keyFacts?.name || 'n/a'}
        content={mdx?.frontmatter?.keyFacts?.country || 'n/a'}
      />
      <ContentContainer>
        <InfoColumn>
          <IntroductionContainer>
            <MDXRenderer>{mdx?.body || 'n/a'}</MDXRenderer>
          </IntroductionContainer>
          <LoadableSimpleReactLightbox>
            <Accordion content={accordionContent.edges} imageData={visuals.edges} />
          </LoadableSimpleReactLightbox>
        </InfoColumn>
        <FactsColumn>
          {keyFactItems.map(keyword => (
            <FactItem key={keyword} title={t(`facts.keywords.${keyword}`)} content={keyFacts![keyword] || ''} type={FactItemType.list} />
          ))}
        </FactsColumn>
      </ContentContainer>
    </Index>
  )
}

export default ProjectInfo

export const query = graphql`
  query ProjectInfo($locale: String!, $title: String!, $project: String!) {
    allMdx(filter: { frontmatter: { template: { in: ["project-detail", "project-info"] } }, fields: { project: { eq: $project } } }) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            locale
            isDefault
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
    visuals: allImageSharp(filter: { fields: { project: { eq: $project } } }, sort: { order: ASC, fields: resolutions___originalName }) {
      edges {
        node {
          id
          fluid(maxWidth: 1000) {
            originalName
            ...GatsbyImageSharpFluid_noBase64
          }
          fields {
            i18nCaptions {
              de
              en
            }
          }
        }
      }
    }
    accordionContent: allMdx(
      filter: { frontmatter: { template: { eq: "accordion" } }, fields: { project: { eq: $project }, locale: { eq: $locale } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            locale
            subject
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
          body
        }
      }
    }
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale }, project: { eq: $project } }) {
      id
      body
      fields {
        locale
        project
        subject
      }
      frontmatter {
        title
        keyFacts {
          climate
          coordinates
          country
          keywords
          name
          region
        }
      }
    }
  }
`
