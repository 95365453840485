import * as React from 'react'
import { useState } from 'react'
import styled from '../../../styles/styled'
import { ProjectInfoQuery } from '../../../graphql-types'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { transparentize } from 'polished'
import Images from './Images'
import LoadedSRLWrapper from '../../images/LoadableSRLWrapper'
import { useTranslation } from 'react-i18next'

type ContentItem = ProjectInfoQuery['accordionContent']['edges'][0]

interface AccordionProps {
  content: ContentItem[]
  imageData: ProjectInfoQuery['visuals']['edges']
}

interface AccordionItemProps {
  imageData: ProjectInfoQuery['visuals']['edges']
  title: string
  active: boolean
  handleOnClick: () => void
  content: ContentItem
  type: AccordionItemType
}

const AccordionContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.white};
`

const AccordionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`

const AccordionListItem = styled.li`
  border-top: 1px solid ${props => props.theme.colors.white};
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemHeader = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  justify-content: space-between;
  letter-spacing: 0.8px;
  background-color: ${props => ({ active }) => {
    return active ? transparentize(0.8, props.theme.colors.white) : transparentize(1, props.theme.colors.black)
  }};
  ${({ active }) => {
    return active
      ? `
      svg {
        transform: rotate(180deg);
      }
    `
      : `
      svg {
        transform: rotate(0deg);
      }
    `
  }};
  &:hover {
    cursor: pointer;
    background-color: ${props => transparentize(0.9, props.theme.colors.lime)};
  }
  svg {
    display: inline-block;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    width: 1em;
    height: 1em;
    transition: transform 0.1s ease-in-out;
  }
`

const Title = styled.div`
  flex: 1 1 auto;
  font-family: ${props => props.theme.fonts.headerFont};
  font-size: ${props => props.theme.dimensions.headingSizes.h4}em;
  text-transform: uppercase;
  color: ${props => props.theme.colors.yellow};
`
const Icon = styled.div`
  flex: 0 0 auto;
  font-size: ${props => props.theme.dimensions.headingSizes.h4}em;
  color: ${props => props.theme.colors.white};
`

const ItemContent = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  padding: ${props => props.theme.dimensions.containerPadding}rem;
  color: ${props => props.theme.colors.white};
  overflow-y: auto;
  strong {
    color: ${props => props.theme.colors.white};
  }
  h3 {
    margin-bottom: 20px;
  }
`

type AccordionItemName = 'climate' | 'visuals' | 'population' | 'geographicDescription' | 'climateChangeImpacts' | 'references'
type AccordionItemType = 'mdx' | 'img'

export const AccordionItemNames: { [key: string]: AccordionItemName } = {
  climate: 'climate',
  visuals: 'visuals',
  population: 'population',
  geoDescription: 'geographicDescription',
  climateImpact: 'climateChangeImpacts',
  references: 'references'
}

interface AccordionData {
  name: AccordionItemName
  type: AccordionItemType
}

const data: AccordionData[] = [
  { name: AccordionItemNames.climate, type: 'mdx' },
  { name: AccordionItemNames.visuals, type: 'img' },
  { name: AccordionItemNames.population, type: 'mdx' },
  { name: AccordionItemNames.geoDescription, type: 'mdx' },
  { name: AccordionItemNames.climateImpact, type: 'mdx' },
  { name: AccordionItemNames.references, type: 'mdx' }
]

interface MdxContentProps {
  mdxData: ContentItem
}

const MdxContent = ({ mdxData }: MdxContentProps) => {
  return <MDXRenderer>{mdxData.node.body}</MDXRenderer>
}

const AccordionItem: React.FC<AccordionItemProps> = ({ imageData, active, handleOnClick, title, type, content }: AccordionItemProps) => {
  return (
    <ItemContainer>
      <ItemHeader onClick={() => handleOnClick()} active={active}>
        <Title>{title}</Title>
        <Icon>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <title>chevron-thin-down</title>
            {/* eslint-disable-next-line max-len */}
            <path d="M17.418 6.109c0.272-0.268 0.709-0.268 0.979 0s0.271 0.701 0 0.969l-7.908 7.83c-0.27 0.268-0.707 0.268-0.979 0l-7.908-7.83c-0.27-0.268-0.27-0.701 0-0.969s0.709-0.268 0.979 0l7.419 7.141 7.418-7.141z" />
          </svg>
        </Icon>
      </ItemHeader>
      <ItemContent visible={active}>
        {type === 'mdx' && <MdxContent mdxData={content} />}
        {type === 'img' && <Images images={imageData} />}
      </ItemContent>
    </ItemContainer>
  )
}

const Index: React.FC<AccordionProps> = ({ content, imageData }: AccordionProps) => {
  const [activeItem, setActiveItem] = useState('')

  const { t } = useTranslation()
  const toggleAccordion = (itemName: string): void => {
    setActiveItem(activeItem === itemName ? '' : itemName)
  }
  const filteredContent = (name: AccordionItemName): ContentItem => {
    return content.filter(item => item?.node?.fields?.subject === name)[0]
  }

  return (
    <AccordionContainer className="project-accordion-container">
      <AccordionList>
        <LoadedSRLWrapper>
          {data.map(item => (
            <AccordionListItem key={item.name}>
              <AccordionItem
                title={t(`info.accordion.${item.name}`)}
                content={filteredContent(item.name)}
                imageData={imageData}
                type={item.type}
                active={item.name === activeItem}
                handleOnClick={() => toggleAccordion(item.name)}
              />
            </AccordionListItem>
          ))}
        </LoadedSRLWrapper>
      </AccordionList>
    </AccordionContainer>
  )
}

export default Index
