import * as React from 'react'
import loadable from '@loadable/component'

const SimpleReactLightbox = loadable(() => import('simple-react-lightbox'), {
  fallback: <div>Loading...</div>
})

const LoadableSimpleReactLightbox: React.FC<{}> = ({ children }) => {
  if (typeof window === 'undefined') {
    return null
  }

  return <SimpleReactLightbox>{children}</SimpleReactLightbox>
}

export default LoadableSimpleReactLightbox
