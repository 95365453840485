import * as React from 'react'
import styled from '../../styles/styled'

export enum FactItemType {
  list,
  header
}

interface FactItemProps {
  title: string
  content: string
  type: FactItemType
}

interface TypeProp {
  type: FactItemType
  color?: string
}

const StyledKeyFact = styled.div`
  display: block;
  margin-bottom: 15px;
`

const StyledFactTitle = styled.h2<TypeProp>`
  font-size: ${props => ({ type }: TypeProp) =>
    type === FactItemType.list ? `${props.theme.dimensions.headingSizes.h4}rem` : `${props.theme.dimensions.headingSizes.h1}rem`};
  color: ${props => props.theme.colors.white};
  text-transform: upperCase;
  margin-bottom: ${({ type }: TypeProp) => (type === FactItemType.list ? `.15rem !important` : `.5rem`)};
  margin-top: 0;
`

export const StyledFactContent = styled.p<TypeProp>`
  color: ${props => ({ color }: TypeProp) => (color ? color : props.theme.colors.lime)};
  font-size: ${props => props.theme.dimensions.headingSizes.h4}rem;
  text-transform: ${({ type }: TypeProp) => (type === FactItemType.list ? 'none' : 'upperCase')};
`

const FactItem: React.FC<FactItemProps> = ({ title, content, type }: FactItemProps) => (
  <StyledKeyFact>
    <StyledFactTitle type={type}>{title}</StyledFactTitle>
    <StyledFactContent type={type}>{content}</StyledFactContent>
  </StyledKeyFact>
)

export default FactItem
